import React, { useState, ChangeEvent, useRef, useEffect } from "react";
import jwt_decode from "jwt-decode";

import PushFilter from "components/push_main/PushFilter";

import { client, cookieManagerClass, printFormDataFC } from "static/ts/tools";
import { letObjectInteface, decodedTokenInterface } from "static/interface/types";
//import css
import "assets/css/push.css";

import { pushFilterStateInterface } from "static/interface/types";
import { useNavigate } from "react-router-dom";

function toLocalISOString(date: Date) {
    const off = date.getTimezoneOffset();
    const absoff = Math.abs(off);
    return new Date(date.getTime() - off * 60 * 1000).toISOString().slice(0, -1) + (off > 0 ? '-' : '+') + (absoff / 60).toString().padStart(2, '0') + ':' + (absoff % 60).toString().padStart(2, '0');
}

const cookieManager = new cookieManagerClass();

const PushMain = () => {

  const [title, setTitle] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [mainText, setMainText] = useState<string>("");
  const [image, setImage] = useState<File | null>(null);
  const [sendDateTime, setSendDateTime] = useState<string>("");

  const [pushFilter, setPushFilter] = useState<pushFilterStateInterface>({
    operatingSystem: "",

    birthDateStart: "",
    birthDateEnd: "",

    dueDateStart: "",
    dueDateEnd: "",

    registerDateStart: "",
    registerDateEnd: "",

    lastAccessDateStart: "",
    lastAccessDateEnd: "",

    event: [],

    nick: [],
    name: [],
    phoneNumber: [],
    email: [],

    gender: "",
    pregnant: "",
    address_main: "",
    address_sub: "",
    signUpSource: "",

    registerPath: "",

  });

  const [tokens, setTokens] = useState<string[]>([]);
  const [tokenLoading, setTokenLoading] = useState<boolean>(false);
  // const [activateFilter, setActivateFilter] = useState<string>("normal_filter");
  const [activateFilter, setActivateFilter] = useState<number>(4);
  const [resMode, setResRMode] = useState<boolean>(false);
  const [pushLoading, setPushLoading] = useState<boolean>(false);

  const filter_info: letObjectInteface = {};

  const titleInputRef = useRef(null);
  const bodyInputRef = useRef(null);

  const navigator = useNavigate();

  const getTokenOnClick = () => {
    setTokenLoading(true);

    const context: letObjectInteface = { pushAgree: pushFilter["pushAgree"] };

    client
      .post("/api/member/filter/getToken", context)
      .then((response) => {
        const data = response.data;
        let count: number = 0;
        const nomal_tokens: string[] = [];
        const error_tokens: string[] = [];

        for (const d of data) {
          if (d.appId !== "" && d.appId.length === 36) {
            nomal_tokens.push(d.appId);
          } else {
            error_tokens.push(d.appId);
            count = count + 1;
          }
        }

        console.log(`${nomal_tokens.length}개의 정상 토큰`);
        console.log(`${count}건의 문제 있는 토큰값 확인`);
        console.log(`${nomal_tokens.length + count} 총합`);

        if (process.env.NODE_ENV === "development") {
          for (const t of error_tokens) {
            console.log(`token : ${t}`);
          }
        }
        setTokens(nomal_tokens);
      })
      .catch((error) => {
        console.error(error); // 에러 출력
      })
      .finally(()=>{
        setTokenLoading(false);
      })
  }

  const pushOnClick = () => {
    setTokens([]);
    setPushLoading(true);

    const formdata: FormData = new FormData();

    if (image) {
      formdata.append("file", image);
    }

    if(activateFilter !== 2){
      const tokens_list: string[] = [];
      for (const t of tokens) {
        tokens_list.push(t);
      }
      
      for (const t of tokens_list) {
        formdata.append("token_list[]", t);
      }
    }

    formdata.append("title", title);
    filter_info["제목"] = title;

    formdata.append("message", mainText);
    filter_info["본문"] = mainText;

    formdata.append("url", url);
    filter_info["url"] = url;

    const token: string | null = cookieManager.getCookie("user_token");

    if (token !== null) {
      const decode: decodedTokenInterface = jwt_decode(token);
      if (decode) {
        //formdata.append("sender", decode.mb_id);
        filter_info["작성자"] = decode.mb_id;
      }
    }

    const interval: number = 3;
    let sign: boolean = true;

    const date_time_now: Date = new Date(Date.now());
    let date_time: Date;

    if (resMode === false) {
      date_time = new Date(Date.now());
      date_time.setMinutes(date_time.getMinutes() + interval);
    } else {
      date_time = new Date(sendDateTime);

      if (sendDateTime === "") {
        sign = false;
        alert("발송 예약시간을 설정해주세요.");
      } else if (date_time_now >= date_time) {
        sign = false;
        alert("이미 지나간 시간 입니다. 예약시간을 다시 설정해주세요.");
      }
    }

    if (sign) {
      //  생성시간
      const created_time_localISOString = toLocalISOString(date_time_now);
      formdata.append("created_time", created_time_localISOString);
      
      const now_date = `${date_time_now.getFullYear()}-${date_time_now.getMonth() < 9 ? "0" + String(date_time_now.getMonth() + 1) : date_time_now.getMonth() + 1}-${date_time_now.getDate() < 10 ? "0" + String(date_time_now.getDate()) : date_time_now.getDate()}`;
      const now_time = `${date_time_now.getHours()}:${date_time_now.getMinutes()}:${date_time_now.getSeconds()}`;
      const now_date_time_print = `${now_date} ${now_time} GMT+0900`;
      filter_info["생성 시간"] = now_date_time_print;

      // 예약 시간
      const send_time_localISOString = toLocalISOString(date_time);
      formdata.append("send_time", send_time_localISOString);

      const date: string = `${date_time.getFullYear()}-${date_time.getMonth() < 9 ? "0" + String(date_time.getMonth() + 1) : date_time.getMonth() + 1}-${date_time.getDate() < 10 ? "0" + String(date_time.getDate()) : date_time.getDate()}`;
      const time: string = `${date_time.getHours() < 10 ? "0" + String(date_time.getHours()) : date_time.getHours()}:${date_time.getMinutes() < 10 ? "0" + String(date_time.getMinutes()) : date_time.getMinutes()}:00`;
      const send_date_string = `${date} ${time} GMT+0900`;
      filter_info["예약 시간"] = send_date_string;
    }

    // printFormData(formdata);
    formdata.append("filter", JSON.stringify(filter_info));

    for (let [key, value] of formdata.entries()) {
      console.log(`${key}: ${value}`);
    }

    let push_url = "/api/push/create";
    if(activateFilter === 2){
      push_url = "/api/push/all";
    }

    if (sign) {
      client
        .post(push_url, formdata)
        .then((res) => {
          console.log(res);
          setPushLoading(false);
          alert("완료되었습니다.");
          navigator("/PushHistory");
        })
        .catch((err) => {
          console.log(err);
          setPushLoading(false);
          alert("error가 발생했습니다.");
        });
      }
  };

  const inageUploadOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file) {
        setImage(file);
      }
    }
  };

  useEffect(() => {
    setTokens([]);
  }, [activateFilter])

  return (
      <div className="push_wrap">
        <div className="title">푸시 보내기</div>
        <div className="div_title">
          <label>
            <span>1</span>수신 조건 설정
          </label>
        </div>
        <div className="div_wrap">

          {/* 푸쉬필터 */}
          <PushFilter pushFilter={pushFilter} setPushFilter={setPushFilter} activateFilter={activateFilter} setActivateFilter={setActivateFilter} tokens={tokens} setTokens={setTokens} tokenLoading={tokenLoading} setTokenLoading={setTokenLoading} />
          
          {
            (activateFilter === 2 || activateFilter === 3)
            &&  <div>
                    <button
                        className="normal_btn margin_top_10"
                        onClick={()=>{
                            getTokenOnClick();
                        }}
                    >
                        토큰가져오기
                    </button>
                </div>
          }
          <div>
            <label>
              수신 예상 인원 : {tokens.length}
              <img src={require("static/img/233F6D505786DA870A.gif")} width={"17px"} hidden={!tokenLoading} />
            </label>
          </div>

        </div>
        <div className="div_title">
          <label>
            <span>2</span>메세지
          </label>
        </div>
        <div className="div_wrap">
          <div className="massage_wrap">
            <label>제목</label>
            <input
              ref={titleInputRef}
              className="normal_input"
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              value={title}
              placeholder="제목을 입력해주세요"
            />
          </div>
          <div className="massage_wrap">
            <label>내용</label>
            <textarea
              ref={bodyInputRef}
              className="normal_textarea"
              onChange={(e) => {
                setMainText(e.target.value);
              }}
              value={mainText}
              placeholder="내용을 입력해주세요"
            />
          </div>
          <div className="massage_wrap">
            <label>url</label>
            <input
              className="normal_input"
              onChange={(e) => {
                setUrl(e.target.value);
              }}
              value={url}
              placeholder="https://abc.com/xyz"
            />
          </div>
          <div className="massage_wrap">
            <label>사진선택</label>
            <input type={"file"} accept="image/jpg,image/png,image/jpeg,image/gif" onChange={inageUploadOnChange} />
          </div>
        </div>
        <div className="div_title">
          <label>
            <span>3</span>발송예약
          </label>
        </div>
        <div className="div_wrap flex_wrap">
          <div>
            <input type={"checkbox"} checked={resMode} onChange={({ target: { checked } }) => setResRMode(checked)} />
            <input
              type={"datetime-local"}
              value={sendDateTime}
              disabled={!resMode}
              onChange={(e) => {
                const value = e.target.value.replace("T", " ").replace(/\..*/, "");
                setSendDateTime(value);
              }}
            />
          </div>
        </div>

        <div>
          <button className={tokens.length > 0 || !tokenLoading ? "send_btn active" : "send_btn no_active"} onClick={pushOnClick} disabled={tokens.length === 0}>
            push
            <img src={require("static/img/233F6D505786DA870A.gif")} width={"17px"} hidden={!pushLoading}></img>
          </button>
        </div>
      </div>
  );
};

export default PushMain;
