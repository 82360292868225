import axios, { AxiosRequestConfig } from "axios";
import { localDataInterface, pageDataInterface, pageDataAfterEditInterface } from "../interface/types";
import { LOCATION_DATA } from "../contant/hard_data";
import moment from "moment";

const axiosConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: "https://luvmom-server-nest-7rhhhgtp5q-an.a.run.app",
};

export const client = axios.create(axiosConfig);

export const printFormDataFC = (obj: FormData) => {
    // FormData의 key 확인
    console.log("KEY [");
    for (let key of obj.keys()) {
        console.log(key);
    }
    console.log("]");

    // FormData의 value 확인
    console.log("VALUE [");
    for (let value of obj.values()) {
        console.log(value);
    }
    console.log("]");
};

export const serchLocationListFC = (key_name: keyof localDataInterface, key_value: string) => {
    for (const item of LOCATION_DATA) {
        if (item[key_name] !== undefined && item[key_name] === key_value) {
            return item;
        }
    }
};

export const checkStartEndDateFC = (start: string, end: string) => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    return startDate <= endDate;
};

// export const editDataFC = (data: pageDataInterface[]) => {
//   const newData: pageDataAfterEditInterface[] = [];

//   for (const d of data) {
//     const item: pageDataAfterEditInterface = {
//       no: 0,
//       title: "",
//       body: "",
//       url: "",
//       picture: "",
//       createdTime: "",
//       sendTime: "",
//       delivered: 0,
//       response_rate: 0,
//       filter: "",
//       sender: "",
//       status: "",
//     };

//     item.no = Number(d.no);
//     item.title = d.title;
//     item.body = d.body;
//     item.url = d.url;
//     item.picture = d.picture;

//     const cd = new Date(d.createdTime);
//     const create_day = `${cd.getFullYear()}-${cd.getMonth() + 1 < 10 ? "0" + String(cd.getMonth() + 1) : cd.getMonth() + 1}-${cd.getDate() < 10 ? "0" + String(cd.getDate()) : cd.getDate()}`;
//     const create_time = `${cd.getHours() < 10 ? "0" + String(cd.getHours()) : cd.getHours()}:${cd.getMinutes() < 10 ? "0" + String(cd.getMinutes()) : cd.getMinutes()}`;
//     const create_date = `${create_day} ${create_time}`;
//     item.createdTime = create_date;

//     const sd = new Date(d.sendTime);
//     const send_day = `${sd.getFullYear()}-${sd.getMonth() + 1 < 10 ? "0" + String(sd.getMonth() + 1) : sd.getMonth() + 1}-${sd.getDate() < 10 ? "0" + String(sd.getDate()) : sd.getDate()}`;
//     const send_time = `${sd.getHours() < 10 ? "0" + String(sd.getHours()) : sd.getHours()}:${sd.getMinutes() < 10 ? "0" + String(sd.getMinutes()) : sd.getMinutes()}`;
//     const send_date = `${send_day} ${send_time}`;
//     item.sendTime = send_date;

//     item.delivered = d.delivered;
//     item.response_rate = d.delivered === 0 ? 0 : Number((d.click / d.delivered).toFixed(2));
//     item.filter = d.filter;
//     item.sender = d.sender;
//     item.status = d.status;

//     newData.push(item);

//     console.log(item);
//   }

//   return newData;
// };

export const getPregnancyWeeksFC = (dueDate: Date): number => {
    const current_time = moment(new Date()).utc();
    const due_date = moment(dueDate);
    const pregnancy_start_date = due_date.subtract(280, "d");
    const days_of_pregnancy = current_time.diff(pregnancy_start_date, "d");
    const week_of_pregnancy = Math.floor(days_of_pregnancy / 7);
    if (process.env.NODE_ENV === "development") {
        console.log(`현재 시간 : ${current_time}`);
        console.log(`출산 예정일 : ${due_date}`);
        console.log(`임신 날짜 : ${pregnancy_start_date}`);
        console.log(`임신 일 수 : ${days_of_pregnancy}`);
        console.log(`임신 주 수 : ${week_of_pregnancy}`);
    }
    return week_of_pregnancy;
};

export class cookieManagerClass{
    // 쿠키를 설정하는 함수
    setCookie = (
        {
            name, 
            value, 
            days,
            path,
        }:{
            name: string;
            value: string;
            days?: number;
            path?: string;
        }
    ) => {
        let path_value = "/";
        if(path){
            path_value = path
        }

        const cookie_parts: string[] = [
            `${name}=${encodeURIComponent(value)}`, // 값 인코딩
            `path=${path_value}`
        ];

        // HTTPS 환경에서만 'secure' 추가
        if (window.location.protocol === 'https:') {
            cookie_parts.push("secure")
            cookie_parts.push("SameSite=Strict")
        }else{
            cookie_parts.push("SameSite=Lax")
        }
        
        if (days !== undefined && days > 0) {
            const expires = new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString();
            cookie_parts.push(`expires=${expires}`)
        }
        
        document.cookie = cookie_parts.join("; ");
    }

    // 쿠키값을 가져옴
    getCookie = (name: string) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
            return decodeURIComponent(parts[1].split(';').shift() || ""); // 값 디코딩
        }
        return null;
    }

    // 특정 쿠키 제거 함수
    deleteCookie = ({name, path}:{name: string; path?: string;}) => {
        let path_value = '/';
        if(path){
            path_value = path;
        }

        const cookie_parts: string[] = [`${name}=`, `path=${path_value}`, "expires=Thu, 01 Jan 1970 00:00:00 GMT"];

        // HTTPS 환경에서만 'secure' 추가
        if (window.location.protocol === 'https:') {
            cookie_parts.push("secure")
            cookie_parts.push("SameSite=Strict")
        }else{
            cookie_parts.push("SameSite=Lax")
        }

        document.cookie = cookie_parts.join("; ");
    };

    // 모든 쿠키 제거 함수
    deleteAllCookies = () => {
        const cookies = document.cookie.split(';');
        for (const cookie of cookies) {
            const eqPos = cookie.indexOf('=');
            const name = eqPos > -1 ? cookie.slice(0, eqPos) : cookie;

            this.deleteCookie({name, path: "/"})
        }
    };
}
