import "assets/css/push_history_table.css";
import { pageDataAfterEditInterface } from "static/interface/types";
import { Link, useNavigate } from "react-router-dom";
import { client } from "static/ts/tools";
import noimg from "assets/images/push/noimg.png";
interface Props {
  columns: { Header: string; accessor: string }[];
  data: pageDataAfterEditInterface[];
}

const PushHistoryTable = ({ columns, data }: Props) => {

    const navigate = useNavigate();

    // 유효성 검사 함수
    function isValidDate(dateString: string): boolean {
        return !isNaN(Date.parse(dateString));
    }

    const today_datetime = new Date();

    const formatNumberWithComma = (number: number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return (
        <div className="push_wrapper">
            <div className="push_table_wrap">
                <table className="push_table_style">
                    <thead>
                        <tr>
                            {columns.map((item, index) => (
                                <th 
                                    key={item.accessor}
                                    style={{
                                        backgroundColor: "white",
                                    }}
                                >
                                    {item.Header}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((item, index) => {

                                let send_datetime = null
                                if(item.send_time){
                                    send_datetime = isValidDate(item.send_time) ? new Date(item.send_time) : null;
                                }

                                let printed_send_time = `${item.send_time}`;
                                if(send_datetime){
                                    const ymd = `${send_datetime.getFullYear()}-${send_datetime.getMonth() < 9 ? `0${send_datetime.getMonth() + 1}` : send_datetime.getMonth() + 1}-${send_datetime.getDate() < 10 ? `0${send_datetime.getDate()}` : send_datetime.getDate()}`;
                                    const hm = `${send_datetime.getHours() < 10 ? `0${send_datetime.getHours()}` : send_datetime.getHours()}:${send_datetime.getMinutes() < 10 ? `0${send_datetime.getMinutes()}` : send_datetime.getMinutes()}`
                                    printed_send_time = `${ymd} ${hm}`
                                }

                                let isCanCancel = false;
                                if(send_datetime){
                                    if(send_datetime > today_datetime){
                                        isCanCancel = true;
                                    }
                                }

                                return (
                                    <tr key={index}>
                                        {/* <td>{item.no}</td> */}
                                        <td>
                                            <div>{item.title || "-"}</div>
                                            <div
                                                style={{
                                                    whiteSpace: "pre-wrap",
                                                }}
                                            >
                                                {item.message || "-"}
                                            </div>
                                        </td>
                                        <td>
                                            <img src={item.image || noimg} width={"75px"}></img>
                                        </td>
                                        <td>{formatNumberWithComma(item.delivered)}명</td>
                                        {/* <td></td> */}
                                        <td>
                                            {!item.delivered || !item.click || item.delivered === 0
                                            ? 
                                            <>
                                                "0%" 
                                            </>
                                            : 
                                            <>
                                                {((item.click / item.delivered) * 100).toFixed(1)}% <br/><br/> <div className="delivered">({formatNumberWithComma(item.click)})</div>
                                            </>
                                            }
                                        </td>
                                        {/* <td>{item.message || "-"}</td> */}
                                       
                                        <td>
                                           <div className="go_link_btn"><Link to={item.url || ""}>(링크이동)</Link></div>
                                           {/* <div>{item.url || "-"}</div> */}
                                        </td>
                                        <td>{printed_send_time}</td>
                                        {/* <td>{item.sender}</td>
                                        <td>{item.status}</td> */}
                                        <td>
                                            {
                                                isCanCancel
                                                ?   <>
                                                        <button
                                                            onClick={()=>{
                                                                console.log(`/api/push/${item.no}`);

                                                                if(!window.confirm("예약이 취소됩니다.")){
                                                                    return ;
                                                                }

                                                                client
                                                                .delete(`/api/push/${item.no}`)
                                                                .then((res) => {
                                                                  console.log(res);
                                                                  alert("완료되었습니다.");
                                                                  navigate("/");
                                                                })
                                                                .catch((err) => {
                                                                  console.log(err);
                                                                  alert("error가 발생했습니다.");
                                                                });
                                                              }
                                                            }
                                                        >
                                                            예약취소
                                                        </button>
                                                    </>
                                                :   null
                                            }   
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default PushHistoryTable;
