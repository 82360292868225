import React, { useState, useEffect } from "react";
import "assets/css/common.css";
import PushHistoryTable from "components/push_history/PushHistoryTable";
import { client } from "static/ts/tools";
import { PAGE_SET } from "static/contant/hard_data";
import { pageDataAfterEditInterface } from "static/interface/types";
import { COLUMNS } from "static/contant/hard_data";

// const dummy_data: pageDataAfterEditInterface[] = [
//     {
//         body: undefined,
//         createdTime: "NaN-NaN-NaN NaN:NaN",
//         delivered: 0,
//         filter: null,
//         no: 52,
//         picture: undefined,
//         response_rate: 0,
//         sendTime: "NaN-NaN-NaN NaN:NaN",
//         sender: undefined,
//         status: undefined,
//         title: "🥳12월 감사일기 당첨자 발표! 어서 확인해보세요",
//         url: "https://new.luvmom2020.com/bbs/board.php?bo_table=exp_final&wr_id=2921&sca=%EA%B0%90%EC%82%AC%EC%9D%BC%EA%B8%B0",
//     }
// ]

const PushHistory = () => {
    const [numOfData, setNumOfData] = useState<number>(0);
    const [pageLimmite, setPageLimmite] = useState<number>(10);
    const [numOfPage, setNumOfPage] = useState<number>(0);
    const [selectedPage, setSelectedPage] = useState<number>(1);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageData, setPageData] = useState<pageDataAfterEditInterface[]>([]);

    useEffect(() => {
        const initialize_url = "/api/push/initialize"

        client
        .get(initialize_url)
        .then((res) => {
            console.log(initialize_url);
            console.log(res);

            if(res.data){
                setNumOfData(res.data.count);
            }
        })
        .catch((err) => {
            setNumOfData(0);
            console.error(err);
            alert("문제가 발생했습니다. 개발팀에게 문의해주세요.");
        });
    }, []);

    useEffect(() => {
        if (numOfData > 0) {
            setNumOfPage(Math.ceil(numOfData / pageLimmite));
        }else{
            setNumOfPage(1);
        }
    }, [numOfData, pageLimmite]);

    useEffect(() => {
        const push_url = `/api/push/getList?page=${selectedPage}&limit=${pageLimmite}`

        client
        .get(push_url)
        .then((res) => {

            console.log(push_url)
            console.log(res)

            if(res.status >= 200 && res.status < 300){
                const res_data: pageDataAfterEditInterface[] = res.data;

                const ordered_data_list: pageDataAfterEditInterface[] = res_data.sort((a, b) => {
                    const a_datatime = new Date(a.send_time || "");
                    const b_datatime = new Date(b.send_time || "");

                    if(isNaN(a_datatime.getTime()) && isNaN(b_datatime.getTime())){
                        return 0
                    }else{
                        if(isNaN(a_datatime.getTime())){
                            return 1
                        }else if(isNaN(b_datatime.getTime())){
                            return -1
                        }else{
                            if(a_datatime > b_datatime){
                                return -1;
                            }else if(a_datatime < b_datatime){
                                return 1;
                            }else{
                                return 0;
                            }
                        }
                    }
                })
                setPageData(ordered_data_list);
            }else{
                setPageData([]);
            }

        })
        .catch((err) => {
            setPageData([]);
            console.error(err);
            alert("문제가 발생했습니다. 개발팀에게 문의해주세요.");
        });
    }, [selectedPage, pageLimmite]);

    return (
        <div className="push_wrapper">
            <h1>보낸 메세지</h1>

            <PushHistoryTable columns={COLUMNS} data={pageData} />
            
            <div 
                className="table-pagination" 
                style={{ 
                    margin: "5px", 
                    display: "flex", 
                    justifyContent: "center", 
                    alignItems: "center",
                }}>
                <button
                    onClick={() => {
                        setSelectedPage(1);
                    }}
                >
                    {"<<"}
                </button>
                <button
                    onClick={() => {
                        if (selectedPage > 1) {
                        setSelectedPage((prev) => prev - 1);
                        }
                    }}
                >
                    이전
                </button>
                <span>
                    <strong style={{ display: "block", width: "100px", textAlign: "center" }}>
                        <span className="current_num">{selectedPage}</span> / <span className="total_num">{numOfPage}</span>
                    </strong>
                </span>
                <button
                    onClick={() => {
                        if (selectedPage < numOfPage) {
                        setSelectedPage((prev) => prev + 1);
                        }
                    }}
                >
                    다음
                </button>
                <button
                    onClick={() => {
                        setSelectedPage(numOfPage);
                    }}
                >
                    {">>"}
                </button>
            </div>
            <div className="table-pagesize">
                <span>
                    페이지 번호{" "}
                    <input
                        type="number"
                        defaultValue={pageNumber}
                        min={1}
                        max={numOfPage}
                        onChange={(e) => {
                            let page_num: number = Number(e.target.value);
                            if (page_num < 0) {
                                page_num = page_num * -1;
                            }
                            page_num = Math.floor(page_num);
                            if (page_num === 0) {
                                page_num = 1;
                            } else if (page_num > numOfPage) {
                                page_num = numOfPage;
                            }
                            setPageNumber(page_num);
                        }}
                    />
                <button
                    onClick={() => {
                        setSelectedPage(pageNumber);
                    }}
                >
                    이동
                </button>
                </span>
                <select
                    value={pageLimmite}
                    onChange={(e) => {
                        setSelectedPage(1);
                        setPageLimmite(Number(e.target.value));
                    }}
                >
                    {
                        PAGE_SET.map((p, index) => (
                            <option key={index} value={p}>
                                {p}개 씩 보기
                            </option>
                        ))
                    }
                </select>
            </div>
        </div>
    );
};

export default PushHistory;
