import React, { useState } from "react";
import { Route, Routes, Link, BrowserRouter } from "react-router-dom";
import "assets/css/common.css";
import LoginPage from "pages/Client/LoginPage";
// import SignUpMain from "pages/Client/SignUpMain";
import Home from "pages/Client/Home";

const Client = () => {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/Home/*" element={<Home />} />
      <Route path="*" element={<LoginPage />} />
    </Routes>
  );
};

export default Client;
