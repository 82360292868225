import React, { useState, useRef } from "react";
import "assets/css/login.css";
import "assets/css/reset.css";
import background from "assets/images/login/login_b_obj_1000.png";
import { client, cookieManagerClass } from "static/ts/tools";

const cookie_manager = new cookieManagerClass();

const LoginPage = () => {
  const [autoLogin, setAutoLogin] = useState<boolean>(false);
  const [id, setId] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const id_input_ref = useRef<HTMLInputElement>(null);
  const password_input_ref = useRef<HTMLInputElement>(null);

  //  체크박스 체크 상태
  const autoLoginOnClick = () => {
    setAutoLogin((prevValue) => !prevValue);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    // Check if the pressed key is Enter (key code 13)
    if (event.key === 'Enter') {
      loginOnClick();
    }
  };
  const idOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    setId(e.currentTarget.value);
  };

  const passwordOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
  };

  const loginOnClick = () => {
    if(id != "admin"){
      alert("관리자계정을 입력해 주세요")
      return false;
    }
    if (process.env.NODE_ENV === "development") {
      console.log("login on click!!");
    }

    if (id.length === 0) {
      if (id_input_ref.current) {
        id_input_ref.current.focus();
      }
    } else if (password.length === 0) {
      if (password_input_ref.current) {
        password_input_ref.current.focus();
      }
    } else {
      const context = {
        mb_id: id,
        password: password,
      };

      client
        .post("/api/auth/login", context)
        .then((res) => {
          if (process.env.NODE_ENV === "development") {
            console.log(res);
          }
          if (res.data.access_token !== undefined) {
            if (autoLogin) {
              localStorage.setItem("user_token", res.data.access_token);
            }

            cookie_manager.setCookie({
              name: "user_token",
              value: res.data.access_token,
            });

            document.location.href = "/";
          } else {
            alert("아이디나 비밀번호가 잘못되었습니다. 다시 시도해주세요.");
          }
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") {
            console.log("error");
            console.log(err);
          }
          if (err.response && err.response.status === 401) {
            alert("아이디나 비밀번호가 잘못되었습니다. 다시 시도해주세요.");
          } else {
            alert("다시 시도해주세요. 동일현상이 반복적으로 발생할 경우 관리자에게 문의해주세요.");
          }
        });
    }
  };

  return (
    <div id="login_wrapper">
      {/* <!-- 로그인 시작 --> */}
      <div id="mb_login" className="mbskin">
        <div className="wrap_box">
          <img src={background} alt="" />
        </div>
        <div className="mbskin_box">
          <input type="hidden" name="url" value=""></input>
          {/* <div className="login_if_auto chk_box">
            <input id="autoLogin" type="checkbox" checked={autoLogin} onChange={autoLoginOnClick}></input>
            <label htmlFor="autoLogin">자동로그인</label>
          </div> */}
          <div className="login_if_auto chk_box">
            {/* <input id="autoLogin" type="checkbox" checked={autoLogin} onChange={autoLoginOnClick}></input> */}
            <label htmlFor="autoLogin">럽맘 관리자 계정을 입력하세요</label>
          </div>
          <fieldset id="login_fs">
            <label htmlFor="login_id" className="sound_only"></label>
            <input className="frm_input" type="text" name="mb_id" id="login_id" required onChange={idOnChange} maxLength={40} placeholder="로그인(이메일)" ref={id_input_ref} />

            <label htmlFor="login_pw" className="sound_only"></label>
            <input type="password" name="mb_password" id="login_pw" required className="frm_input required ccl-1"  onChange={passwordOnChange} maxLength={40} placeholder="비밀번호" ref={password_input_ref} />
            <button className="btn_submit bc_new" onClick={loginOnClick} onKeyDown={handleKeyPress}>
              로그인
            </button>
            {/* <div id="login_info">
              <div className="login_if_lpl">
                <a href="#" id="login_password_lost_1">
                  아이디 찾기
                </a>
                <a href="#" id="login_password_lost_2">
                  비밀번호 찾기
                </a>
                <Link to="SignUpMain">회원가입</Link>
              </div>
            </div> */}
          </fieldset>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
