import React, { useState, useEffect } from "react";
import { Route, Routes, Link, BrowserRouter } from "react-router-dom";
import "assets/css/common.css";
import PushMain from "pages/Manager/PushMain";
import PushHistory from "pages/Manager/PushHistory";
import { SideBar } from "components/side_bar";
import Fair from "pages/Manager/Fair";
import { cookieManagerClass } from "static/ts/tools";

const cookieManager = new cookieManagerClass;

const Manager = () => {
  useEffect(() => {
    if(!cookieManager.getCookie("user_token")){
      document.location.href = "/"
    }
  }, []);

  return (
    <div className="manager_page">
      <SideBar />
      <div className="main_wrap">
        <Routes>
          <Route path="/" element={<PushHistory />} />
          <Route path="/PushMain" element={<PushMain />} />
          <Route path="/PushHistory" element={<PushHistory />} />
          <Route path="/Fair" element={<Fair />} />
          <Route path="*" element={<PushHistory />} />
        </Routes>
      </div>
    </div>
  );
};

export default Manager;
